// 导入axios
import axios from "axios";
import router from "@/router";
import { Message } from "element-ui";
let userTenantId = "1";
const service = axios.create({
  baseURL: "https://api.mobile.youmanlu.com",
  headers: {
    "Content-Type": "application/json",
    "Tenant-Id": 123,
  },
  timeout: 120000, // 设置超时时间为 60 秒（60000 毫秒）
});

// axios.defaults.baseURL = 'https://api.mobile.youmanlu.com'
// axios.defaults.baseURL = 'https://ielts.dev.bc3.top'

// 请求拦截
service.interceptors.request.use(
  (config) => {
    userTenantId = localStorage.getItem("tenantId")? localStorage.getItem("tenantId"): "1";
    config.headers["Tenant-Id"] = 123;
    // config.headers["Tenant-Id"] = userTenantId;

    let token = localStorage.getItem("token");
    // let token = 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJub3RfeWJzX3VzZXJfa2V5IjoiZjEzZDYzOTctNjU5ZC00ZTViLWI5YWQtN2FjNzg4NjcyZTFkIn0.ULxF6_ZS6HI97XeriDica0CbL1MyzyEGzb90OhL9n_TQanyCaux01qcZ-5IaQ-rCHnb4MyXYcuhwZj_8DDEX0A'
    if (token) {
      config.headers["Authorization"] = token;
      config.headers["ybs-source"] = "web";
    }
    if (config.url.indexOf("app-api") == -1) {
      config.url = "/app-api" + config.url;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code !== 0) {
      // Message({
      //  message: res.message || 'Error',
      //  type: 'error',
      //  duration: 3000
      // })
      if (res.code == 500) {
        // router.push('err500')
      } else if (res.code == 401) {
        localStorage.removeItem("token");
        router.replace("/");
      }
      if (res.code == 404) {
        // router.push('err404')
      }
      if (res.code == 30002) {
        // localStorage.removeItem('token')
        // router.replace('/')
      }
      if (res.code == 30003) {
        localStorage.removeItem("token");
        router.replace("/");
      }

      return response;
    } else {
      return response;
    }
  },
  (error) => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          console.log("错误请求");
          break;
        case 401:
          localStorage.removeItem("token");
          router.replace("/");
          console.log("token失效");
          break;
        default:
          console.log("连接错误");
      }
    } else {
      console.log("连接服务器失败");
    }
    return Promise.reject(error);
  }
);
/**
 * get post
 *
 */
export default class Http {
  static get(url, params) {
    return new Promise((resolve, reject) => {
      service
        .get(url, { params })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  static post(url, data) {
    return new Promise((resolve, reject) => {
      service
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  static put(url, data) {
    return new Promise((resolve, reject) => {
      service
        .put(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
