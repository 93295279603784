import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

// 海鸟雅思用户协议
const privacyarticle = [
  {
    path: "/article/privacy",
    name: "privacy",
    component: () => import("@/views/privacyarticle/privacy.vue"),
  },
  // 海鸟雅思隐私政策
  {
    path: "/article/protocol",
    name: "protocol",
    component: () => import("@/views/privacyarticle/protocols.vue"),
  },
];

// 写作题库
const Writingquestionbank = [
  {
    path: "/writing/details",
    component: () =>
      import("@/views/Writingquestionbank/Writingquestionbank2.vue"),
    meta: {
      title: "写作题库",
    },
  },
  {
    path: "/customWriteling",
    component: () => import("@/views/Writingquestionbank/customWriteling.vue"),
    meta: {
      title: "自定义批改",
    },
  },
];

const routes = [
  {
    path: "/",
    redirect: "/speaking",
    // redirect: "/home",
  },
  //以下是首页部分的组件
  {
    path: "/company-intro",
    name: "company-intro",
    component: () => import("@/views/homepage/home3/index.vue"),
  },
  {
    path: "/share",
    name: "share-intro",
    component: () => import("@/views/share.vue"),
  },
  // {
  //   path: "/home2",
  //   name: "home2",
  //   component: () => import("@/views/homepage/home2.vue"),
  // },
  // {
  //   path: "/home",
  //   name: "home",
  //   component: () => import("@/views/homepage/test.vue"),
  // },
  {
    path: "/protocol",
    name: "protocol",
    component: () => import("@/components/protocol.vue"),
  },
  {
    path: "/privacyPolicy",
    name: "privacyPolicy",
    component: () => import("@/components/privacyPolicy.vue"),
  },
  {
    path: "/testpage",
    name: "testpage",
    component: () => import("@/views/testpage"),
  },
  {
    path: "/testpages",
    name: "testpages",
    component: () => import("@/views/testpages"),
  },
  {
    path: "/mode",
    name: "mode",
    component: () => import("@/views/testpage"),
  },
  {
    path: "/testpage1",
    name: "testpage1",
    component: () => import("@/views/Writeling/part2.0.vue"),
  },
  {
    path: "/writing/correct",
    name: "correct",
    component: () => import("@/views/Writeling/correct.vue"),
  },
  {
    path: "/writing/teacher/correct",
    name: "correct",
    component: () => import("@/views/Writeling/correct2.vue"),
  },
  {
    path: "/testpage2",
    name: "testpage2",
    component: () => import("@/views/Writeling/write2.0.vue"),
  },
  // 口语页面
  {
    path: "/speaking/mock",
    name: "speaking/mock",
    component: () => import("@/views/Speechling/Speackmock.vue"),
  },
  {
    path: "/speaking/details",
    name: "speaking/details",
    component: () => import("@/views/Speechling.vue"),
  },
  {
    path: "/speaking",
    name: "speaking",
    component: () => import("@/views/Speechling/Speechlingbefor"),

    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/AllMode2",
    name: "AllMode2",
    component: () => import("@/views/AllMode/AllMode2.vue"),

    meta: {
      keepAlive: true,
    },
  },
  //写作题库一级页面
  {
    path: "/writing",
    name: "Writeling",
    component: () => import("@/views/Writeling.vue"),
  },
  //写作题库二级页面
  {
    path: "/Writeling_second",
    name: "Writeling_second",
    component: () => import("@/components/Writeling/Secondary_pages.vue"),
  },

  //写作模考页面
  {
    path: "/Writemk_home",
    name: "Writemk_home",
    component: () => import("@/views/Writeling/Writemk_home.vue"),
  },

  {
    path: "/Wmk_part1",
    name: "Wmk_part1",
    component: () => import("@/views/Writeling/WritemockPart1.vue"),
  },
  // 套题模考二级页面（听力）
  {
    path: "/Hearing",
    name: "Hearing",
    component: () => import("@/components/ModelTest/Hearing.vue"),
  },
  // 套题模考二级页面（阅读）
  // {
  //     path: '/Reading',
  //     name: 'Reading',
  //     component: () => import('@/components/ModelTest/Reading.vue')
  // },
  // 套题模考二级页面（写作）
  {
    path: "/Wirting",
    name: "Wirting",
    component: () => import("@/components/ModelTest/Wirting.vue"),
  },
  //写作练习详情页
  {
    path: "/WriteDetail",
    name: "WriteDetail",
    component: () => import("@/components/user/Record_item/WriteDetail.vue"),
  },
  //口语练习详情页
  {
    path: "/OralDetail",
    name: "OralDetail",
    component: () => import("@/components/user/Record_item/OralDetail.vue"),
  },
  //阅读相关页面
  // 2.0阅读一级页面
  {
    path: "/reading",
    name: "Readling",
    component: () => import("@/views/Readling.vue"),
  },
  // 2.0阅读练习页面
  {
    path: "/readPractice",
    name: "readPractice",
    component: () => import("@/views/Readling2/practice.vue"),
  },
  // 2.0阅读练习解析页面 
  {
    path: "/readPracticeDetail",
    name: "readPracticeDetail",
    component: () => import("@/views/Readling2/PracticeDetail.vue"),
  },

  // 2.0阅读模考页面
  {
    path: "/readPracticeMK",
    name: "readPracticeMK",
    component: () => import("@/views/ReadlingMK/practice.vue"),
    props: true,
  },
  // 2.0阅读模考解析页面
  {
    path: "/readPracticeDetailMK",
    name: "readPracticeDetailMK",
    component: () => import("@/views/ReadlingMK/PracticeDetail.vue"),
  },

  //阅读模考页面
  {
    path: "/Readmk_home",
    name: "Readmk_home",
    component: () => import("@/views/Readling/index.vue"),
  },
  //阅读模考详情页面
  {
    path: "/ReadModeDetails",
    name: "ReadModeDetails",
    component: () => import("@/views/Readling/indexDetail.vue"),
  },

  //听力相关页面
  //   听力一级页面
  {
    path: "/listening",
    name: "listening",
    component: () => import("@/views/ListenTest.vue"),
  },
  // 听力题库二级页面
  {
    path: "/listenDetail",
    name: "listenDetail",
    component: () => import("@/views/Listening2/listenDetail.vue"),
  },
  // {
  //     path: '/Listening_second',
  //     name: 'Listening_second',
  //     component: () => import('@/components/Listening/speakTest/listen_pages.vue')
  // },

  // 听力题库--听力详情页
  {
    path: "/Listening_details",
    name: "Listening_details",
    component: () =>
      import("@/components/Listening/speakTest/listen_details.vue"),
  },
  // 模考准备页
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/Listening2/ListenModel/ListenModel.vue"),
  },
  //模考详情页
  {
    path: "/MockTestView/:testType",
    name: "MockTestView",
    component: () => import("@/views/Listening2/ListenModel/MockTestView.vue"),
    props: true,
  },

  // 2.0听力练习页面
  {
    path: "/listenPractice",
    name: "listenPractice",
    component: () => import("@/views/Listening2/listenpractice.vue"),
  },
  //精听
  {
    path: "/Intensivelistening",
    name: "Intensivelistening",
    component: () => import("@/views/Listening2/Intensivelistening.vue"),
  },
  {
    path: "/ModelTest",
    name: "ModelTest",
    component: () => import("@/views/ModelTest.vue"),
  },

  // 2.0个人中心
  {
    path: "/user",
    name: "user",
    component: () => import("@/views/User2.vue"),
  },
  // 口语模考详情页
  {
    path: "/ModeSpeechlingDetail",
    name: "ModeSpeechlingDetail",
    component: () =>
      import("@/components/user/OneMode/ModeSpeechlingDetail.vue"),
  },
  {
    path: "/ModeSpeechlingDetail2",
    name: "ModeSpeechlingDetail2",
    component: () =>
      import("@/components/user/OneMode/ModeSpeechlingDetail2.vue"),
  },
  // 听力模考详情页
  {
    path: "/ModeListeningDetail",
    name: "ModeListeningDetail",
    component: () =>
      import("@/components/user/OneMode/ModeListeningDetail.vue"),
  },
  // 听力单part模考详情页
  {
    path: "/ModeListeningPartDetail",
    name: "ModeListeningPartDetail",
    component: () =>
      import("@/components/user/OneMode/ModeListeningPartDetail.vue"),
  },
  // 听力练习详情页
  {
    path: "/ListeningDetail",
    name: "ListeningDetail",
    component: () => import("@/components/user/MyPractice/ListeningDetail.vue"),
  },
  // 听力练习详情页 教师端
  {
    path: "/ListeningDetailTeacher",
    name: "ListeningDetailTeacher",
    component: () => import("@/components/user/MyPractice/ListeningDetailTeacher.vue"),
  },
  // 阅读模考详情页
  {
    path: "/ModeReadingDetail",
    name: "ModeReadingDetail",
    component: () => import("@/components/user/OneMode/ModeReadingDetail.vue"),
  },
  // 阅读单part模考详情页
  {
    path: "/ModeReadingPartDetail",
    name: "ModeReadingPartDetail",
    component: () =>
      import("@/components/user/OneMode/ModeReadingPartDetail.vue"),
  },
  // 阅读练习详情页
  {
    path: "/ReadingDetail",
    name: "ReadingDetail",
    component: () => import("@/components/user/MyPractice/ReadingDetail.vue"),
  },
  // 阅读练习详情页 教师端
  {
    path: "/ReadingDetailTeacher",
    name: "ReadingDetailTeacher",
    component: () => import("@/components/user/MyPractice/ReadingDetailTeacher.vue"),
  },
  // 升级vip页
  {
    path: "/vipUp",
    name: "vipUp",
    component: () => import("@/components/user/VIP/vipUp.vue"),
  },
  ...Writingquestionbank,
  ...privacyarticle,
  {
    path: "/editor",
    name: "editor",
    component: () => import("@/views/Writeling/editor.vue"),
  },
  {
    path: "/vipremove",
    name: "vipremove",
    // component: () => import('@/views/vipremove.vue')
    component: () => import("@/views/vipremove.vue"),
  },
  {
    path: "/uDmove",
    name: "uDmove",
    component: () => import("@/views/uDmove.vue"),
  },
];

const router = new VueRouter({
  scrollBehavior(to, from, saveTop) {
    if (saveTop) {
      return saveTop;
    } else {
      return { x: 0, y: 0 };
    }
  },
  mode: "history",
  routes,
});

export default router;
