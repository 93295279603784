// lang/en.js
const en = {
  'Language': 'English',
  headertag: [
    // {
    //   index: 0,
    //   active: false,
    //   router: "/home",
    //   Width: "36px",
    //   margin: "0 0 0 0",
    //   routerName: "Home",
    // },
    {
      index: 1,
      active: false,
      router: "/speaking",
      Width: "72px",
      margin: "0 0 0 57px",
      routerName: "Speaking",
    },
    {
      index: 2,
      active: false,
      router: "/writing",
      Width: "72px",
      margin: "0 0 0 64px",
      routerName: "Writing",
    },
    {
      index: 3,
      active: false,
      router: "/listening",
      Width: "86px",
      margin: "0 0 0 50px",
      routerName: "Listening",
    },
    {
      index: 4,
      active: false,
      router: "/reading",
      Width: "86px",
      margin: "0 0 0 50px",
      routerName: "Reading",
    },
    // {
    //   index: 5,
    //   active: false,
    //   router: "/ModelTest",
    //   Width: "86px",
    //   margin: "0 0 0 50px",
    //   routerName: "Model Test",
    // },
    // {
    //   index: 6,
    //   active: false,
    //   router: "/company-intro",
    //   Width: "86px",
    //   margin: "0 0 0 50px",
    //   routerName: "Corporate Services",
    // },
  ],
  Speaking: {
    Speakingbefore: {
      roterHome: 'Home',
      roterNmae: 'Speaking',
      plstxt:"Please enter",
      cardtxt: {
        gotest: 'Go Practice',
        showMore: 'Show More',
        up: 'Collapse',
        flname: 'Category',
        flList: [
          { name: 'All' },
          { name: 'Environment' },
          { name: 'Learning' },
        ],
        xxname: 'Study',
        xxList: [
          { name: 'All' },
          { name: 'Not Studied' },
          { name: 'Studied' },
        ],
        txname: 'Question Type',
        txList: [
          { name: 'Part1' },
          { name: 'Part2' },
        ],
        scname: 'Favorites',
        scList: [
          { name: 'All' },
          { name: 'Not Favorited' },
          { name: 'Favorited' },
        ],
        ndname: 'Difficulty',
        ndList: [
          { name: 'All' },
          { name: 'Easy' },
          { name: 'Medium' },
          { name: 'Hard' },
        ],
        pgname: 'Correction',
        pgList: [
          { name: 'All' },
          { name: 'Yes' },
          { name: 'No' },
        ],
        xjname: 'New/Old',
        xjList: [
          { name: 'All' },
          { name: 'New Questions' },
        ],
      }
    }
  }
};
export default en;
