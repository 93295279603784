import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import axios from 'axios'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Http from "./request/http";
import Api from "./request/api";
import VueContextMenu from 'vue-contextmenu';
import './common/css/common.css'
import './common/css/iconfont.css'
import VueEasyCm from 'vue-easycm'
import 'echarts';
import ECharts from 'vue-echarts';
import i18n from './components/i18n'
Vue.component('ECharts', ECharts);
import 'echarts/lib/chart/bar'; // 导入需要使用的图表类型，例如柱状图
import 'echarts/lib/component/tooltip'; // 导入需要使用的组件，例如提示框
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)


// 动画 animate.css
require('animate.css/animate.min.css');

// 滚动动画 wow.js
import {WOW} from 'wowjs'
Vue.prototype.$wow = new WOW({
  boxClass: 'wow', // default
  animateClass: 'animated', // default
  offset: 150, // default
  mobile: true, // default
  live: false,

  // live为true时，控制台会提示：MutationObserver is not supported by your browser. & WOW.js cannot detect dom mutations, please call .sync() after loading new content.

  callback: function(box) {
    console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
  }
})



Vue.use(VueEasyCm)
import water from "vue-waterfall2";

Vue.use(water);
import Highlighter from 'web-highlighter';
window.highlighter = new Highlighter()
Vue.use(VueContextMenu)
// 等比例缩放引入
import './utils/rem.js'
// 字体引入
import '@/assets/font/font.css'
import md5 from 'js-md5'
Vue.prototype.$md5 = md5;
import VueResource from 'vue-resource';


Vue.use(VueResource);
import { VueJsonp } from 'vue-jsonp';
Vue.use(VueJsonp)
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$api = Api;
Vue.prototype.$Http = Http;
// Vue.prototype.$jsonp = VueJsonp;
new Vue({
  store,
  i18n,
  router,
  components: {
  VueContextMenu,
  },
  created() {
    // 在实例创建时设置页面标题
    document.title = '海鸟雅思';
  },
  render: h => h(App),
}).$mount('#app')
